"use client";

import { ThemeProvider, useTheme } from "@emotion/react";
import { createTheme, useMediaQuery } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Link from "next/link";
import { useSelectedLayoutSegments } from "next/navigation";
import * as React from "react";
import { useEffect, useState } from "react";
import useMenuItems from "../CustomHooks/useMenuitems";
import TabMenuDropdown, { TabMenuProps } from "../TabMenuDropdown";
import { tan } from "../Theme";

interface MenuProps {
  isBreeder?: boolean;
  hasBreederPage?: boolean;
  breederSlug?: string;
}

const HorizontalMenu = ({ isBreeder = false, hasBreederPage = false, breederSlug }: MenuProps) => {
  const segments = useSelectedLayoutSegments();

  const menuItems = useMenuItems(isBreeder, hasBreederPage, breederSlug ?? "");

  const [value, setValue] = useState<string | boolean>(false);

  const theme = useTheme();

  useEffect(() => {
    setValue(() => {
      if (segments[0] === "admin") {
        if (segments[1] === "verify-dogs") return "Verifiser hund";
        else if (segments[1] === "bugs") return "Bugs";
        else if (segments[1] === "articles") return "Artikler";
        else if (segments[1] === "members") return "Medlemmer";
      }

      if (segments[1] === "log") return "Logg";
      if (segments[2] === "waitinglist") return "Venteliste";
      if (segments[2] === "applications") return "Søknader";

      if (segments[1] === "breeder") {
        if (segments[2] === breederSlug && segments[3] === "(homepage)") return "Oppdrett";
        else return "Utforsk";
      }
      if (segments[1] === "dog" || segments[0] === "search") return "Utforsk";

      return false;
    });
  }, [segments]);

  const newTheme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: tan[300],
            backgroundColor: "transparent",
            alignItems: "center",
            paddingBottom: "20px",
            borderRadius: 0,
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "none",
            opacity: 1,
            "@media (max-width:900px)": {
              alignItems: "center",
            },
            "&.Mui-selected": {
              backgroundColor: "transparent",
              color: tan[300],
              "@media (max-width:900px)": {
                alignItems: "center",
              },
            },
          },
        },
      },
    },
  });

  const largeScreen = useMediaQuery(newTheme.breakpoints.up("md"));

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <ThemeProvider theme={newTheme}>
      <Tabs value={value} onChange={handleChange} centered sx={{ paddingTop: "5px" }}>
        {menuItems.map(({ label, href, options }: TabMenuProps, index) => (
          <TabMenuDropdown key={label} LinkComponent={Link} value={label} href={href} label={label} options={options} />
        ))}
      </Tabs>
    </ThemeProvider>
  );
};
export default HorizontalMenu;
