"use client";

import { Container, Grid, Typography } from "@mui/material";
import { CopyrightText, FooterWrapper } from "../../styles/styledComponents";
import { usePathname, useSelectedLayoutSegments } from "next/navigation";

const Footer = () => {
  const path = useSelectedLayoutSegments();
  return (
    <>
      {!path.includes("messages") && (
        <FooterWrapper display={{ xs: "none", md: "flex" }}>
          <Container maxWidth="lg">
            <Grid container justifyContent={"space-between"} pb={"30px"}>
              <div>
                <Typography variant="subtitle2" mb={"30px"}>
                  HappyDogs{" "}
                </Typography>
                <Typography variant="body1">
                  T: +47 47 38 13 43 <br /> E:{" "}
                  <a style={{ textDecoration: "underline" }} href="mailto:marie@happydogs.no">
                    marie@happydogs.no
                  </a>
                  <br /> W: https://happydogs.no
                </Typography>
              </div>
              <div>
                <Typography variant="subtitle2">Postaddresse</Typography>
                <Typography variant="body1">Risbakken 8, 4013 Stavanger, Norway</Typography>
              </div>
              <div>
                <Typography variant="body1">Org.nr.: 931 714 678 </Typography>
                <br /> <br />
                <Typography variant="subtitle2">Chief Editor</Typography>
                Marie Hilander Gjerde <br /> <br /> {/*Give us feedback*/}
              </div>
              <Typography variant="body1">
                <a href={"/information/terms-of-use"} style={{ color: "#fff" }}>
                  {"> "}Brukervilkår
                </a>{" "}
                <br />
                <br />
                <a href={"/information/privacy"} style={{ color: "#fff" }}>
                  {"> "}Personvernopplysninger
                </a>
                <br />
                <br />
                <a href={"/#faq"} style={{ color: "#fff" }}>
                  {"> "}FAQ
                </a>
                <br />
                <br />
                <a href={"/information/aboutus"} style={{ color: "#fff" }}>
                  {"> "}Om oss{" "}
                </a>
                <br />
                <br />
              </Typography>
            </Grid>
            <CopyrightText>Copyright © 2025 - HappyDogs</CopyrightText>
          </Container>
        </FooterWrapper>
      )}
    </>
  );
};

export default Footer;
