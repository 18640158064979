import { useUser } from "@clerk/nextjs";
import { Box, Grow, MenuItem, Popper, Tab, TabProps, Typography } from "@mui/material";
import Link from "next/link";
import { useState } from "react";
import { orange, tan } from "./Theme";

export interface TabMenuProps extends TabProps {
  href: string;
  label: string;
  options?: { label: string; href: string }[];
  imageUrl?: string;
}

const TabMenuDropdown = ({ href, label, options }: TabMenuProps) => {
  const { user } = useUser();
  const isSignedIn = !!user;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  let lock;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    clearTimeout(lock);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    lock = setTimeout(() => setAnchorEl(null), 200);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  return (
    <>
      <Tab
        key={href}
        label={label}
        href={href}
        LinkComponent={Link}
        onMouseOver={handleOpen}
        onMouseLeave={handleClose}
        sx={{ marginRight: { md: 0, lg: "20px" }, marginLeft: { md: 0, lg: "20px" } }}
      />
      {options && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
          onMouseOver={() => clearTimeout(lock)}
          onMouseLeave={handleClose}
          sx={{
            zIndex: 10000,
            position: "relative",
          }}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: "top" }} timeout={250}>
              <Box
                sx={{
                  backgroundColor: orange[15],
                  borderRadius: " 0 0 10px 10px",
                  borderLeft: `1px solid ${tan[50]}`,
                  borderRight: `1px solid ${tan[50]}`,
                  borderBottom: `1px solid ${tan[50]}`,
                  position: "relative",
                  top: "-1px",
                }}
              >
                {options.map(({ href, label }, index) => (
                  <MenuItem key={index} sx={{ width: "100%", padding: "10px", display: isSignedIn ? "block" : "none" }}>
                    <Link href={href}>
                      <Typography variant="body2">{label}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Box>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
};

export default TabMenuDropdown;
