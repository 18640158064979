import { Box, keyframes, Typography } from "@mui/material";

// Define a subtle pulse animation
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

interface CounterProps {
  amount: number;
}

const UnreadCounter = ({ amount }: CounterProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#990f0c",
        minWidth: { md: "24px", xs: "20px" },
        height: { md: "24px", xs: "20px" },
        padding: "0 6px",
        borderRadius: "12px",
        position: "absolute",
        bottom: 2,
        right: -8,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
        animation: `${pulseAnimation} 2s infinite ease-in-out`,
        border: "2px solid #ffffff",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
          backgroundColor: "#b31210",
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: { md: "0.75rem", xs: "0.7rem" },
          lineHeight: 1,
        }}
      >
        {amount}
      </Typography>
    </Box>
  );
};

export default UnreadCounter;
